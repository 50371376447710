//BREAKPOINTS

@mixin mobil($viewportMax:601) {
	@media screen and (max-width: $viewportMax+px) {
		@content; } }

@mixin mobil-land($viewportMin:480, $viewportMax:568) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin nexus($viewportMin:359, $viewportMax:480) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin tablet($viewportMin:600, $viewportMax:1024) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }


@mixin onlytablet($viewportMin:767, $viewportMax:959) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin smalltablet($viewportMin:600, $viewportMax:767) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin flextablet($viewportMax:767) {
	@media screen and (max-width: $viewportMax+px) {
		@content; } }

@mixin smalltablet-land($viewportMin:568, $viewportMax:800) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin ipad($viewportMin:800, $viewportMax:940) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin fluid($viewportMax:1024) {
	@media screen and (max-width: $viewportMax+px) {
		@content; } }


@mixin desk($viewportMin:940, $viewportMax:1280) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@mixin mq($viewportMax) {
	@media screen and (max-width: $viewportMax+px) {
		@content; } }

@mixin vmax($viewportMax) {
	@media screen and (max-width: $viewportMax+px) {
		@content; } }

@mixin vmin($viewportMin) {
	@media screen and (min-width: $viewportMin+px) {
		@content; } }

@mixin motog($viewportMin:359, $viewportMax:600) {
	@media screen and (min-width: $viewportMin+px) and (max-width: $viewportMax+px) {
		@content; } }

@include fluid;

