//@titles

@mixin title-section {
	margin: 0;
	padding: 20px 10px;
	@include bg($color);
	//	background-image: linear-gradient(left, $color 0%, $colorSec 50%)
	@include white;
	margin: 0 0 0px;
	@include upper;
	@include fs(3, 100%, 700);
	@include ls(1px); }

main {
	h1 {
		@include title-section;
		@include noScroll;
		@include relative;
		.icon {
			background: url('../img/logotipo-white.gif');
			@include top-right(-26.5px, 15%);
			@include size(160px 180px);
			@include background;
			@include opa(.4); } } }
//		+before
//			+content('')
//			+bot-right(0, 15%)
//			+bg($colorSec)
//			+size(6px 100%)
//		+after
//			+content('')
//			+bot-right(0, 17%)
//			+bg($colorTer)
//			+size(6px 100%)
//

//	h2
//		color: $color-subtitle
//		margin: $space 0 0

