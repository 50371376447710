//RESETS

@mixin noFont {
  font-size: 0; }

@mixin noFloat {
  float: none; }

@mixin noList {
  list-style-image: none !important;
  list-style-type: none !important;
  list-style: none !important; }

@mixin noDeco {
  text-decoration: none; }

@mixin noUnder {
  text-decoration: none; }

@mixin noAppearance {
  -webkit-appearance: none; }

@mixin noBorder {
  border: none $i; }

@mixin noScroll {
  overflow: hidden; }

@mixin noShadow {
  box-shadow: none; }

@mixin noText($indent: -999) {
  text-indent: $indent+rem;
  overflow: hidden; }

@mixin noFlex {
  flex: none; }

@mixin noRadius {
  @include radius(0px); }


@mixin reset {
  margin: 0 $i;
  padding: 0 $i; }

@mixin noBg {
  @include bg(none $i); }
