

//@Blog
main.blog {
	.row {
		@include mb(0); }
	.blog-big {
		@include br(thin solid $gray-light);
		@include pr(20px);

		img {
			max-width: 100%;
			height: auto;
			@include centered(20px, 0);
			@include block;
			border: 4px solid $gray-light; } }
	.blog-mini {
		padding: 0px 20px 60px; }




	article {
		@include pb(40px);
		@include bb(thin solid $gray-light);
		@include relative;
		@include mb(40px);
		@include w100;

		h2 {
			@include fs(2, 100%, 500);
			@include gray-dark; }
		.text {
			margin: 20px 0; }
		figure {
			@include size(350px 200px);
			@include right;
			@include ml(20px);
			@include figure;
			img {
				@include s100; } }
		.icon {
			@include ib;
			@include bot-left(-22px, 50%);
			@include ml(-22px);
			padding: 0 10px;
			@include tCenter;
			background-size: 50%;
			@include block;
			@include size(44px);
			i {
				@include fs(2.4);
				@include gray-light;
				@include mt(8px); } } } }


main.blog,
main.blogDetail {
	.row {
		@include mb(0); }
	h2 {
		@include block;
		@include fs(2, 100%, 500);
		@include gray-dark;
		@include mb(3px); }

	article {
		@include w100;
		@include mb(20px);
		@include w100; }

	figure {
		@include none; }

	aside {
		@include block;
		@include w100;
		@include bg($color);

		.keywords {
			padding: 10px;
			h3 {
				@include fs(1.6, 100%, 700);
				@include tCenter;
				@include upper;
				@include white;
				@include mb(10px); }
			a {
				@include borderFix;
				@include ib;
				@include va;
				@include noBg;
				@include wauto;
				@include under;
				padding: 0 $i;
				margin: 3px 5px 10px;
				@include white;
				@include odd {
					@include fs(2);
					@include ib;
					@include va;
					margin: 0px 5px 10px; } } } }

	.date,
	.autor,
	.category {
		@include noFont;
		@include ib;
		@include va;
		@include wauto;
		@include mr(5px);
		@include pr(5px);
		@include br(thin dotted $gray);
		a {
			@include co($colorSec); }
		a,
		span {
			@include ib;
			@include va;

			@include fs(1.3, 100%, 300); }
		i {
			@include fs(1.4);
			@include ib;
			@include va; } }


	.category {
		@include noBorder; }


	.text {
		margin: 20px 0; } }



main.blogDetail {
	aside {
		flex: 0 120px;
		@include ml(20px);
		.date,
		.autor,
		a {
			@include w100;
			@include fs(1.3, 130%, 400);
			@include block;
			@include bg($color);
			@include white;
			@include tCenter;
			padding: 10px;
			i {
				@include mb(10px);
				@include block;
				@include fs(3); } }
		.date,
		.autor {
			margin: 0;
			i {
				@include white; } }
		.autor {
			@include bg($colorSec); }
		a {
			@include bg($gray);
			@include hover {
				@include bg($gray-dark); }

			i {
				@include mb(10px);
				@include block;
				@include fs(3); } } }
	article {
		@include tLeft;
		flex: 1;
		@include noFont;
		@include pb(30px);
		//		+bb(thin solid $gray-light)
		.text {
			@include tLeft; }

		img {
			@include size(350px 200px);
			@include right;
			margin: 0 0 20px 20px;
			@include figure;
			img {
				@include s100; } }
		.icon {
			@include ib;
			@include bot-left(50%, -12px);
			@include ml(-22px);
			@include bgWhite;
			padding: 0 10px;
			i {
				@include fs(2);
				@include gray-light; } } }

	figure {
		@include block; }
	aside {
		@include block;
		flex: 0 200px;
		//	.category
		//		i
		//			+fs(1.3)
		//		a
 }		//

	.keywords {
		@include bg($color);
		padding: 10px;
		h3 {
			@include fs(1.6, 100%, 700);
			@include tCenter;
			@include upper;
			@include white;
			@include mb(10px); }
		a {
			@include borderFix;
			@include ib;
			@include va;
			@include noBg;
			@include wauto;
			padding: 0 $i;
			margin: 5px 5px 10px;
			@include odd {
				@include fs(2);
				@include ib;
				@include va;
				margin: 0px 5px 10px; } } } }

#existing-comments {
	margin: 10px 0; }

#create-comment {
	@include bg($gray-light);
	padding: 20px;
	h4 {
		margin: 10px 0;
		@include co($black $i); }
	label {
		@include co($black $i); } }

#comment.form-control {
	height: 200px;
	border: 1px solid $gray-light;
	padding: 10px;
	@include focus {
		border: 1px solid $gray-light; } }

#commentPost {
	.form_submit {
		@include right;
		margin: 20px 0; } }

//@blog

.myComments {
	.name,
	.date,
	.image {
		@include ib;
		@include wauto;
		@include va;
		@include noBorder;
		@include fw(700); }
	.row {
		@include mb(0); }

	.comment {
		border: thin solid $gray-light + 10 $i;
		@include bg($gray-light + 20 $i);
		padding: 10px;
		@include mb(10px);
		@include odd {
			@include bg($gray-light + 10 $i); } }

	.comments-profile {
		@include br(thin solid $gray-light); }

	.comments-text {
		@include pl(20px); }

	.res_form {
		@include mt(40px);
		@include w100;
		@include relative;
		@include block;
		h4 {
			@include mb(20px); } } }
