
//PSEUDOCLASS
@mixin hover {
	&:hover {
		@content; } }

@mixin focus {
	&:focus {
		@content; } }

@mixin after {
	&::after {
		@content; } }

@mixin before {
	&::before {
		@content; } }



//PSEUDOSELECTORS

@mixin last {
	&:nth-last-child {
		@content; } }

@mixin first {
	&:nth-first-child {
		@content; } }

@mixin nth1 {
	&:nth-of-type(1) {
		@content; } }

@mixin nth2 {
	&:nth-of-type(2) {
		@content; } }

@mixin nth3 {
	&:nth-of-type(3) {
		@content; } }

@mixin nth4 {
	&:nth-of-type(4) {
		@content; } }

@mixin nth5 {
	&:nth-of-type(5) {
		@content; } }

@mixin nth6 {
	&:nth-of-type(6) {
		@content; } }



@mixin odd {
	&:nth-of-type(odd) {
		@content; } }

@mixin even {
	&:nth-of-type(even) {
		@content; } }

@mixin content($content: ':)') {
	content: $content; }
