/*PAGINATION*/

.pagination {
	@include tCenter;
	ul {
		@include box;
		@include space($cen);
		li {
			@include relative;
			margin: 0 -2px;
			a,
			span {
				@include block;
				@include bg($transparent);
				@include co($colorSec);
				@include size(30px);
				@include fs(1.4, 23px, 700);
				@include pointer;
				@include transition;
				border: 4px solid $colorSec;
				@include radius(100%);
				@include hover {

					@include bg($transparent);
					@include co($color);
					border: 4px solid $color; }

				i {
					@include mt(3px); } } }
		.active {
			@include z(99999);
			a,
			span {
				@include co($color);
				@include bg($transparent);
				border: 4px solid $color; } }

		.prev,
		.next {
			a {
				@include lh(22px $i); } } } }

//.pager-rounded
//	+tCenter
//	+w100
//	+clear
//	+relative
//	+z(99)
//	ul
//		+wauto
//		+inline-block
//		box-shadow: 1px 1px 0px rgba(0,0,0,.2)
//		+radius(10px)
//		li
//
//			border-left: thin solid $gray-light
//			+m0
//			+left
//			a
//				+block
//				+bg(white)
//				+co($gray-dark)
//				+size(35px)
//				+fs(1.6, 35px, null)
//				+pointer
//				+transition
//				+hover
//					+co($pink)
//				i
//					+mt(8px)
//			.active
//				+bg($pink)
//				+co(white)
//				+hover
//					+co(white)
//		.first
//			border-left: thin solid $gray-light
//
//		.last
//			border-right: thin solid $gray-light
//
//		.prev
//			+noBorder
//			a
//				+radius-custom(10px, 0, 0, 10px)
//
//		.next
//			+noBorder
//			a
//				+radius-custom(0, 10px, 10px, 0)




