//SIZES

@mixin s100 {
	@include w100;
	@include h100; }

@mixin w100 {
	width: 100%; }

@mixin h100 {
	height: 100%; }

@mixin hv {
	width: 100vh;
	height: 100vh; }

@mixin maxH($maxheight) {
	max-height: $maxheight+rem; }

@mixin maxW($maxwidth) {
	max-width: $maxwidth+rem; }

@mixin minH($minheight) {
	min-height: $minheight+rem; }

@mixin minW($minwidth) {
	min-width: $minwidth+rem; }

@mixin hauto {
	height: auto; }

@mixin wauto {
	width: auto; }

@mixin sauto {
	@include size(auto); }
