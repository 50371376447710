//@projects

$height-box: 300px;

main.projects {
	h1 {
		@include mb(0px); }

	.panel {
		@include mb(20px);
		height: 100%;
		min-height: auto;
		padding: 10px;
		.container {
			width: 100%; }
		.text {
			@include bb(thin dotted $gray);
			@include pb(10px); } }
	.myText {

		@include radius-custom(0px, 0px, 4px, 4px);
		@include z(999999);
		@include bgBlue();
		@include fs(2, 130%, 100);
		padding: 10px;
		@include co(white);
		@include transition(.2s);
		height: 70px;
		@include tCenter;
		@include relative;
		@include mt(-70px);
		pointer-events: none; }

	.myText-active {
		//		+z(-1)
		@include opa(0); }

	.theBox {
		@include relative;
		@include noScroll;
		@include mb(20px); }

	.myCategories {
		@include bg(white); } }

.da-thumbs {
	@include relative;

	.myBox {
		@include bg($white);
		padding: 0px;
		@include relative;

		@include w100;
		@include block;
		height: $height-box;
		margin: 0 0 20px;
		h2 {

			@include fs(1.6, 110%, 700);
			padding: 10px 0;
			margin: 20px 20px 0px;
			@include upper;
			@include co($color); }
		a {
			@include block;
			@include relative;
			margin: 0;
			@include tCenter;
			@include bgWhite;
			@include radius(4px);
			@include s100;
			img {
				@include ib;
				@include relative;
				margin: 30px 0 0;
				height: 80px; }
			div {
				@include absolute;
				@include bg($gray-light);
				@include s100;
				margin: 0;
				span {
					@include block;
					padding: 10px 0;
					margin: 20px 20px 20px;
					@include fs(1.4, 140%, 300);
					@include co($gray-dark);
					border-bottom: 1px solid $color;
					box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(055, 055, 055, 0.3); } } } } }


main.projects.categories {
	ul.list-group {
		@include mb(20px);
		@include bg($gray-light);
		@include tCenter;
		> li {
			@include bb(1px solid $gray-light + 10);
			padding: 15px 30px;
			@include transition;
			@include ib;
			> a {
				@include block;
				@include co($colorSec);
				@include fs(1.6, 100%, 400);
				@include before {
					@include co($colorSec $i); } }

			@include hover {
				@include bg($white);
				> a {
					@include co($color $i);
					@include before {
						@include co($color $i); } } }
			> ul {
				@include none; } }
		> .class_active {
			margin: 0 $i;
			> a {
				@include co($color);
				@include before {
					content: '\f111'; }
				@include hover {
					@include co($colorSec); } } } }
	.text {
		@include mb(20px); }

	.ico-more {
		@include none; } }



main.detail {
    article {
        padding: 0px $i; }
    .text {
        @include bg(white);
        padding: 10px 20px; }
    .title2 {
        @include bg(white $i); } }

main.projects.categories {
	ul.list-group {

		> li {
			a {
				@include before {
					font-family: fontAwesome;
					color: $white;
					@include mr(10px);
					content: '\f10c';
					@include transition(.4s); }

				@include hover {
					@include before {
						content: '\f192'; } } }


			@include nth1 {
				a {
					@include before {
						content: '\f0ae'; } } } } } }



