//FIXES

@mixin transparent {
	background: rgba(0, 0, 0, 0); }

@mixin borderFix {
	border: .1rem solid rgba(0, 0, 0, 0); }

@mixin cero {
	margin: 0;
	padding: 0; }


//=reset
//	margin: 0
//	padding: 0
//	text-decoration: none
//	border: none
//	outline: none
//	list-style: none

@mixin sizing {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; }


@mixin p0 {
	padding: 0; }

@mixin m0 {
	margin: 0; }

@mixin clip {
	background-clip: padding-box; }





/*HELPER*/

//body
//	+font
////	+legible


//p
//	+cero
//	+co($gray-font)
//	+fs(1.8, 130%, normal)
//	+font
//	+mb(10px)
//	&:nth-last-child
//		+m0


//a
//	+link-colors($blue, $blue + 1, $blue + 2, white, $gray +1) // a, a:hover, a:active, a:visited, a:focus
//	+noUnder

//ul,
//li
//	+reset
//
//input[type='submit']
//	+noBorder

//HELPERS

@mixin b1 {
	border: .1rem solid darkred !important; }

@mixin b2 {
	border: .1rem solid navy !important; }

@mixin b3 {
	border: .1rem solid lime !important; }

@mixin b4 {
	border: .1rem solid chocolate !important; }

@mixin b5 {
	border: .1rem solid violet !important; }
