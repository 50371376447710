//@Header

@mixin preheader-active {
	@if $preheader-active {
		@debug "$preheader-active is #{$preheader-active}";
		@include bt(2px solid $header-colorBorder); }

	@else {
		@debug "$preheader-active is #{$preheader-active}"; } }

@mixin header-shadow {
	@if $header-shadow {
		@debug "$header-shadow is #{$header-shadow}";
		box-shadow: 0px 3px 3px rgba(128, 128, 128, .5); }

	@else {
		@debug "$header-shadow is #{$header-shadow}"; } }

header {
	@include preheader-active;
	@include header-shadow;
	@include bc(white);
	@include relative;
	.contentmenu {
		@include bc($white); }
	.row {
		@include mb(0); }
	.logo {
		margin: 10px 0;
		@include block;
		@include w100;
		height: $logo-height;
		@include background(contain);
		@include bp(center, left);
		@include ml(20px); } }

header.fixed {
	@include header-shadow;

	@include top-left();
	@include w100;
	@include z(99);
	.row {
		@include mb(0);
		.col {
			@include p0; } } }


header.noFixed {
	@include relative;
	@include w100;
	nav {
		@include mt(40px); }
	.logo {
		height: 80px;
		margin: $space-mini 0; }
	li {
		a {
			@include co($color);
			@include transition(.2s);
			@include hover {
				@include co($colorSec); } } } }

.menu-top {
	@include wauto;
	@include ib;
	@include mt(20px);
	@include noFont;
	.myBtn {
		@include co($white);
		height: 35px;
		@include ib;
		@include pr(10px);
		margin:  0 2px;
		@include bg($colorTer);
		@include fs(1.5, 37px, 300);
		@include transition(.2s);
		span {
			@include size(37px 35px);
			pointer-events: none;
			@include ib;
			@include bc($colorQua);
			@include mr(10px);
			@include tCenter;
			i {
				@include f(1.8rem); } }
		i {
			margin: 0 10px;
			@include f(1rem); }
		@include hover {
			@include bg($colorTer + 20); }
		@include nth1 {
			@include bg($color);
			span {
				@include size(40px 35px);
				@include bg($color + 20); }
			@include hover {
				@include bg($color + 20); } } }

	//	+tRight
	//	+noFont
	//	+bg($gray-light - 10)
	//	+wauto
	//	+ib
	//	+mt(10px)
	//	padding: 10px 10px
	//
	//	.btn-link
	//		+fs(1.5, 100%, 300)
	//		+co($gray - 20)
	//		+ib
	//		padding: 0 15px
	//		margin: 0
	//		+transition(.2s)
	//		i
	//			+mr(8px)
	//		+nth1
	//			+br(thin dotted $gray - 10)
	//		+nth2
	//			+br(thin dotted $gray - 10)
	//		+hover
 }	//			+co($color)


header {
	.slogan {
		@include ib;
		@include va;
		@include tLeft;
		@include mt(18px);
		@include ml(20px);
		@include fs(2.2, 100%, 700);
		@include co($color);
		span {
			@include block;
			@include fw(300); } }
	.social {
		@include top-right(100px, -5px);
		@include z(9999);
		@include fixed;
		width: auto;
		margin: 20px 2px 0 0;
		a {
			@include block;
			@include size(36px);
			@include bg($color);
			@include radius(4px);
			@include mb(5px);
			@include tCenter;
			i {
				@include fs(1.8);
				@include co($white);
				@include mt(9px); }
			@include hover {
				@include bg($color + 30); } } }


	.actions {
		@include noFont;
		margin: 12px -13px 10px 0;
		.myBtn {
			@include co($white);
			height: 35px;
			@include ib;
			@include pr(10px);
			margin:  0 5px;
			@include bg($colorTer);
			@include fs(1.5, 37px, 300);
			@include transition(.2s);
			span {
				@include size(37px 35px);
				pointer-events: none;
				@include ib;
				@include bc($colorQua);
				@include mr(10px);
				i {
					@include f(1.8rem); } }
			i {
				margin: 0 10px;
				@include f(1rem); }
			@include hover {
				@include bg($colorTer + 20); }
			@include nth2 {
				@include bg($color);
				span {
					@include size(40px 35px);
					@include bg($color + 20); }
				@include hover {
					@include bg($color + 20); } } } } }

