body {
    @include bc(#E4E6EB); }
main {
    @include bc(white); }

.panel {
    margin: 0;
    padding: 20px 0;
    @include block;
    @include fs(1.7, 150%, 400);
    @include bg($gray-light - 5); }


//@Home

section.text-info {
    article {
        min-height: 200px;
        height: 200px $i;
        .btn {
            margin-top: 5px $i; } }
    article.left {
        p {
            transition: all 600ms;
            text-align: justify;
            color: #4c4e51;
            font-size: 3rem;
            line-height: 140%;
            font-weight: 300;
            padding: 15px;
            background: #fff;

            i {
                color: #004382;
                font-size: 2.8rem; } } } }

section.logos {
    @include bg($white);
    @include tCenter;
    padding: 20px 0;

    img {
        @include ib;
        @include va;
        margin: 0 30px 0px;
        height: 54px;
        @include grayFilter(0);
        @include transition(.6s);
        @include pointer; }
    .text {
        @include fs(1.8, 130%, 300);
        @include co($gray-dark);
        @include ml(10px);
        width: 50%;
        @include ib;
        @include tLeft; }
    @include hover {
        img {
            @include grayFilter(0); } }
    a {
        @include ib;
        @include va;
        @include fs(2, 100%, 300);
        @include co($gray);
        @include mt(0px); }
    .actions {
        @include ib;
        @include va;
        @include tLeft;
        width: 30%;
        a {
            @include block;
            @include co($color);
            @include upper;
            @include fs(1.6, 100%, 500);
            margin: 0 0 5px;
            @include hover {
                @include co($color - 30); }
            i {
                @include mr(7px);
                @include ib;
                @include va;
                @include mt(-3px);
                @include fs(.9);
                @include co($colorSec); } } } }



section.quotes {
    @include relative;
    @include bg($gray-light - 10);
    @include tCenter;
    @include noScroll;
    padding: 30px 0;
    .text {
        @include fs(2.2, 160%, 100);
        @include co($gray - 30);
        @include tCenter;
        @include noScroll;
        @include relative;
        padding: 0 100px;
        @include z(9); }
    .icon {
        @include bot-left(50%, 15%);
        @include size(80px);
        margin: 0 0 -40px 0;
        @include z(1);
        i {
            @include fs(8);
            @include co($gray-light - 20); } }

    .icon2 {
        @include bot-right(50%, 15%);
        @include size(80px);
        @include rotate(180);
        margin: 0 0 -40px 0;
        @include z(1);
        i {
            @include fs(8);
            @include co($gray-light - 20); } } }

.videosGallery {
    .item-gallery {
        @include relative;
        @include tCenter;
        padding: 10px;
        @include w100;
        @include bg($gray-light);
        .icon {
            @include top-left(50%, 50%);
            @include size(60px);
            @include tCenter;
            @include radius(100%);
            margin: -30px 0 0 -30px;
            pointer-events: none;
            i {
                @include f(5rem);
                @include co($white);
                @include mt(15px);
                @include ml(10px); } } }

    .big {
        padding: 20px;
        @include mb(10px);
        .icon {
            @include top-left(50%, 50%);
            @include size(80px);
            @include tCenter;
            @include bg($color);
            @include radius(100%);
            margin: -40px 0 0 -40px;
            pointer-events: none;
            i {
                @include f(5rem);
                @include co($white);
                @include mt(15px);
                @include ml(10px); } } } }


.modalx {
    h2 {
        @include co($color);
        @include upper;
        @include mb(20px);
        @include fs(2.6, 100%, 500); }
    .modal-close {
        @include top-right(10px, 10px); }
    .steps {
        @include tCenter;
        @include mt(20px);
        .step {
            padding: 5px 0;
            @include bb(thin solid $gray);
            .number {

                @include radius(100%);
                @include pt(17px);
                @include ib;
                @include size(50px);
                @include co($white);
                @include patt(5);
                @include f(2rem);
                @include mr(10px); }

            .text {
                @include ib;
                width: 90%;
                @include tLeft; }
            @include nth1 {
                .number {
                    @include bc($color + 60); } }
            @include nth2 {
                .number {
                    @include bc($color + 50); } }
            @include nth3 {
                .number {
                    @include bc($color + 40); } }
            @include nth4 {
                .number {
                    @include bc($color + 30); } }

            @include nth5 {
                .number {
                    @include bc($color + 20); } }

            @include nth5 {
                .number {
                    @include bc($color + 10); } } } } }


main.trip {
    figure {
        height: 300px;
        @include w100;
        @include background;
        border: 4px solid $gray-light;
        @include mb(20px); } }

section.numbers {
    height: 170px;

    @include noScroll;
    @include bc($colorSec);
    @include patt(5);
    @include co($white);
    .otherSlide {
        height: 120px;
        .number {
            @include fs(7, 100%, 400);
            @include mt(20px);
            @include ib;
            @include co(white); }
        .data {
            @include fs(4, 100%, 400);
            @include ib;
            @include relative;
            @include mt(40px);
            @include ml(20px);
            @include z(9); }
        .otherNumber {
            @include fs(12, 100%, 400);
            @include bot-right(0px, 10%);
            @include opa(.2);
            @include z(8); } }
    h2 {
        @include tCenter;
        @include fs(3.2, 100%, 400);
        @include pt(20px);
        @include block;
        @include co($colorTer + 10); } }


main.askUs {
    @include background;
    @include relative;
    height: 580px;
    figure {
        height: 500px;
        @include background(contain);
        background-position: right bottom; }
    .consultant {
        @include top-right;
        @include size(400px 100%);
        @include opa(0);
        @include transition(.4s);
        .caption {
            @include pb(20px);
            @include bb(thin solid $gray);
            @include tRight;
            @include top-right(60px, 280px);
            width: 220px;
            h2 {
                @include mb(20px); }
            .text {
                @include font-text; } } }


    .form {
        @include top-left(50px, 0);
        @include radius(4px);
        padding: 20px;
        width: 400px;
        height: 320px;
        @include bgBlack(.8);
        @include none;
        @include co($white);
        h2 {
            @include mb(20px);
            @include co($white); }
        .text {
            @include co($white);
            @include font-text; }
        textarea {
            margin: 20px 0 0;
            @include radius(4px);
            resize: none;
            height: 100px;
            @include transition(.4s);
            @include co(white $i);
            @include font-text;
            padding: 5px;
            border: thin solid $white;
            @include focus {
                border: thin solid $colorTer; } }
        .btn {
            @include bg($blue);
            @include mt(20px); }
        label {
            @include mb(10px);
            @include block; }
        select {
            @include block;
            @include mb(20px); } }

    .consultant-active {
        @include opa(1);
        @include transition(.4s); }

    .form-active {
        @include block; } }

main.process {
    .help {
        @include bg($gray-light);
        @include mt(20px);
        border: thin solid $gray-light - 10;
        padding: 8px 10px;
        @include fs(1.4, 130%, 300); }

    .status {
        @include co($colorQua); }
    .btn-link {
        @include fs(1.4, 100%, 300);
        @include co($color);
        @include hover {
            @include co($color - 20); } }
    .row {
        @include mb(0); }
    .panel {
        @include tLeft;
        .icon {
            @include size(40px);
            @include ib;
            @include mt(5px);
            @include mr(20px);
            @include radius(100%);
            @include bg($color);
            @include tCenter;
            i {
                @include co($white);
                @include fs(3);
                @include mt(5px); } }
        p {
            @include ib;
            width: 84%;
            @include fs(1.6, 150%, 300);
            @include tLeft; }
        span {
            @include co($color);
            @include fw(700); } }

    .filters {
        margin: 20px 0;
        @include bg($gray-light);
        @include pb(20px);
        .input-field {
            margin: 5px 20px; }
        .btn {
            @include mr(20px); }
        h2 {
            @include bg($color);
            @include co($white);
            padding: 10px;
            i {
                @include fs(2);
                @include mr(10px); } }
        select {
            height: 35px;
            @include bb(1px solid $color);
            @include mb(20px); }
        .text {
            margin: 10px 20px 20px;
            @include pb(10px);
            @include fs(1.4, 130%, 300);
            @include bb(thin solid $gray-light - 20); } }


    table {
        @include mt(20px);
        @include bg(white);
        thead {

            @include bg($color);
            @include co($white);
            @include upper;
            @include fs(1.6, 100%, 400);
            tr {
                th {
                    @include radius(0); } } }
        th, td {
            padding: 10px 20px; }

        .btn {
            @include ib; }
        a {
            @include fs(1.4, 150%, 400);
            @include hover {
                @include co($color - 20); } } } }



.detail.process {
    @include pb(20px);
    .process-status {
        @include bg($gray-light);
        padding: 20px;
        .autor {
            @include bb(thin dotted $gray-light - 30);
            padding: 0 0 10px; }

        h3 {
            @include upper;
            margin: 15px 0; }
        a {
            @include fs(1.4, 130%, 300);
            @include co($color);
            @include block;
            @include mb(5px);
            @include hover {
                @include co($color - 20); }

            i {
                @include mr(10px); } } }

    .intro {
        @include bg($colorSec + 10);
        @include co($gray-dark);
        padding: 20px;
        @include mb(20px);
        @include fs(1.6, 150%, 500); } }
footer,.postFooter {

	.blue2 {
		@include bc($color);
		@include relative;
		@include ib;
		width: 100%; } }
section.carousel {
	.container {
		width: 100% $i; } }
section.ourclients.carousel {
	@include pt(0px $i);
	h2 {
		@include bc($white);
		padding: 10px 0px;
		margin-bottom: 16px; } }

main.detail-news {
    .title {
        figure {
            background-size: contain $i;
            background-repeat: no-repeat $i;
            background-position: center,center $i; } } }

@media screen and (min-width: 320px) and (max-width: 375px) {
    header {
        .contentmenu {
            .button-collapse {
                top: 15px;
                left: 30px;
                i {
                    font-size: 4rem $i; } } } }
    section.banner {
        .caption {
            left: 5%;
            width: 90%;
            height: auto $i; } }
    section.text-info {
        article {
            height: auto $i;
            p {
                line-height: 120% $i;
                font-size: 1.7rem $i; }
            .btn {
                margin-top: 10px $i; } }
        article.left {
            p {
                font-size: 2rem $i; } } }
    main.detail-services {
        article {
            text-align: center $i;
            h2 {
                text-align: center $i; } } }
    main.detail-news {
        .title {
            figure {
                background-size: contain $i;
                background-repeat: no-repeat $i;
                background-position: center,center $i; } } } }
@media screen and (min-width: 376px) and (max-width: 425px) {
    header {
        .contentmenu {
            .button-collapse {
                top: 15px;
                left: 30px;
                i {
                    font-size: 4rem $i; } } } }
    section.banner {
        .caption {
            left: 5%;
            width: 90%;
            height: auto $i;
            top: 50px $i; } }
    section.text-info {
        article {
            height: auto $i;
            p {
                line-height: 120% $i;
                font-size: 2rem $i; }
            .btn {
                margin-top: 10px $i; } }
        article.left {
            p {
                font-size: 2.2rem $i; } } }
    main.detail-services {
        article {
            text-align: center $i;
            h2 {
                text-align: center $i; } } }
    main.detail-news {
        .title {
            figure {
                background-size: contain $i;
                background-repeat: no-repeat $i;
                background-position: center,center $i;
                width: 100% $i; } } }
    footer {
        .blue2 {
            figure {
                width: 100% $i; } }
        ul {
            li {
                width: 50% $i; } } } }
@media screen and (min-width: 426px) and (max-width: 992px) {
    header {
        .contentmenu {
            .button-collapse {
                top: 15px;
                left: 30px;
                i {
                    font-size: 4rem $i; } } } }
    section.text-info {
            article {
                height: auto $i;
                p {
                    line-height: 120% $i;
                    font-size: 2rem $i; }
                .btn {
                    margin-top: 10px $i; } }
            article.left {
                p {
                    font-size: 2.2rem $i; } } }
    main.detail-services {
        article {
            text-align: center $i;
            h2 {
                text-align: center $i; } } }
    footer {
        figure {
            width: 100% $i; }
        ul {
            li {
                width: 25% $i; } } }
    main.detail-news {
        .title {
            figure {
                background-size: contain $i;
                background-repeat: no-repeat $i;
                background-position: center,center $i;
                width: 100% $i; } } } }
@media screen and (min-width: 993px) and (max-width: 1024px) {
    section.areas {
        article {
            height: 450px $i; } }
    section.highlight {
        .news {
            article {
                height: 270px $i; } } }
    main.detail-news {
        .title {
            figure {
                background-size: contain $i;
                background-repeat: no-repeat $i;
                background-position: center,center $i; } } } }
