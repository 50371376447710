
@mixin border {
	border: 6px solid $gray-light; }


@mixin ts {
	text-shadow: -1px -1px 1px #bbb, 1px 1px 1px #ccc; }




@mixin paragraph {
	p, span, li {
		@include fs(1.4, 150%, 400);
		color: $color-txt; } }

@mixin para {
	@include fs(1.4, 150%, 400);
	color: $color-txt; }



@mixin shadow {
	box-shadow: 0px 1px 1px rgba(0,0,0,.3); }




/**/

@mixin title_home {
	@include upper;
	@include cero;
	@include fs(1, 100%, 800);
	@include w100;
	@include relative;
	@include gray;
	margin: 40px 0 10px;
	@include tCenter;
	@include before {
		@include content('');
		@include w100;
		height: 1px;
		@include bg($gray-light);
		@include top-left(50%, 0);
		@include z(1); }


	span {
		@include bg(#f3f5f8 url("../img/bg_texture-home.png") center top);
		@include relative;
		@include z(999);
		@include inline-block;
		padding: 0 10px; } }

@mixin titleArticle {
	@include co($blue);
	@include cero;
	@include fs(1.8, 100%, 400);
	padding: 0px 0 10px;
	@include upper;
	@include bb(thin solid $gray-light);
	@include ml(2px); }













@mixin shadow {
	box-shadow: 0px 1px 1px rgba(0,0,0,.3); }






@mixin help {
	@include white;
	@include bg($gray-light);
	padding: 5px;
	@include mt(1px);
	@include noFont;
	@include gray;
	i {
		@include mr(5px);
		@include fs(2);
		@include ib;
		@include gray; }
	p {
		@include cero;
		@include ib;
		width: 85%;
		@include fs(1.1, 130%, 300);
		@include gray-dark; } }




@mixin title-home {
	flex: 100%;
	@include tCenter;
	@include white;
	@include fs(1.1, 100%, 800);
	@include upper;
	@include mb(10px);
	@include relative;
	@include before {
		@include content('');
		@include top-left(5px, 100px);
		@include block;
		@include size(400px 1px);
		@include bg($gray-dark + 10); }
	@include after {
		@include content('');
		@include top-right(5px, 100px);
		@include block;
		@include size(400px 1px);
		@include bg($gray-dark + 10); } }




@mixin subTitle {
	@include fs(2.2, 100%, 600);
	@include mb(20px);
	@include upper; }


@mixin background($back: cover) {
	background-size: $back;
	background-position: center center;
	background-repeat: $nr; }

@mixin figure {
	@include noScroll;
	@include bg(white);
	@include tCenter;
	@include background; }


//@TITULOS



@mixin ts {
	text-shadow: 1px 2px 0px white, 0 0 0 white, 1px 2px 0px white, 2px 2px 0px white; }

@mixin title-home {
	@include relative;
	@include wauto;
	@include fs(2.6, 100%, 700);
	@include upper;
	@include ib;
	padding: 0 $space-big $space-mini;

	margin: $space-mini 0 $space; }


