// ==================================================
// $Variables
// ==================================================
// Supports
// @media min-width
// @media max-width
// @media min-width and max-width

$viewport-increment: 0.1px; // customize to suite

// Desktop
$desktop: 1024px; // customize to suite
$pre-desktop: $desktop - $viewport-increment;

// Tablet
$tablet: 768px; // customize to suite
$pre-tablet: $tablet - $viewport-increment;

// Palm
$palm: 640px; // customize to suite
$post-palm: $palm + $viewport-increment;

// Mobil
$mobil: 320px; // customize to suite
$post-mobil: $mobil + $viewport-increment;

// Constraints
$min: min-width;
$max: max-width;


// ==================================================
// $Media Query @Mixin
// ==================================================

@mixin mQ($constraint, $viewport1, $viewport2: null) {

  @if $constraint == $min {

    @media screen and ($min: $viewport1) {
      @content;
    }

  } @else if $constraint == $max {

    @media screen and ($max: $viewport1) {
      @content;
    }

  } @else {

    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }

  }

}

@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;

  @if length($value) > 1 {
    $height: nth($value, 2);
  }

  @if is-size($height) {
    height: $height;
  } @else {
    @warn "`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
  }

  @if is-size($width) {
    width: $width;
  } @else {
    @warn "`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
  }
}









