//@referrals

main.referrals {
	@include pb(20px);
	.panel {
		@include mb(20px); }

	.row {
		@include mb(0px); }


	.btn {
		@include mt(10px); }

	.bx-pager.bx-default-pager {
		@include none; }

	.carousel {
		padding: 20px $i;
		.container {
			width: 100% $i; }
		.text {
			@include fs(1.6, 150%, 100);
			@include cursive; }
		h3 {
			padding: 5px;
			@include block; }
		i {
			@include f(5rem $i); } } }
