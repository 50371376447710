


//@News


main.news {
	h1 {
		@include mb(20px); }
	.follow {
		.social {
			@include tCenter;
			margin: 20px 0;
			a {
				@include ib;
				@include tCenter;
				@include size(40px);
				@include radius(100%);
				margin: 0 3px;
				@include bg($colorTer);
				@include co($white);
				@include transition;
				@include hover {
					@include bg($colorSec); }

				i {
					@include mt(8px);
					@include fs(2.2);
					@include co($white);
					@include transition(.2s); } } } }
	.youtube,
	.facebook,
	.twitter {
		width: 100%;
		@include noScroll;
		@include bg($white); }
	.facebook {
		iframe {
			width: 294px $i;
			border: none $i; } }
	h3 {
		@include bc($blue);
		@include patt(11);
		@include fs(2);
		padding: 8px 10px;
		@include co($white);
		i {
			@include mr(10px); } }
	.twitter {
		@include mt(20px); }
	.youtube {
		@include mb(20px);
		iframe {
			@include w100; } }
	.row {
		@include mb(0); }
	.panel {
		@include mb(20px); }
	article {
		@include bb(thin solid $gray-light - 30);
		@include relative;
		@include mb(30px);
		@include pb(20px);
		height: auto;
		@include w100;
		.text {
			@include font-text; }
		h2 {
			@include lh(150%);
			@include co($blue); }
		.date {
			@include co($gray-dark - 10);
			i {
				@include co($gray-dark - 10); } }
		figure {
			@include size(350px 200px);
			@include left;
			@include mr(20px);
			@include background;
			@include border; }

		a.btn-link {
			@include ib;
			@include co($colorSec);
			@include hover {
				@include co($colorTer); } }

		.icon {
			@include bot-left(-12px, 50%);
			@include ml(-24px);
			padding: 0 10px;
			@include bg(white);
			@include patt(5);
			@include block;
			@include tCenter;

			i {
				@include fs(2);
				@include co($gray-light - 30); } } } }

.noMargin {
	@include mb(0); }

main.news {
	@include fluid {
		article {
			height: auto;
			@include pb(60px);
			figure {
				@include tCenter;
				max-width: 100%;
				@include noFloat;
				margin: 0 0 20px; } } } }

main.detail-news {
    article {
        @include bg(white $i); }
    .title2 {
        @include bg(white $i); } }
