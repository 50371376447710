//@FAQ



main.faq {
	.myTabs {
		li {
			@include bg($gray-light);
			padding: 10px;
			@include transition(.2s);
			@include bb(thin solid $gray);
			a {
				@include fs(1.4, 100%, 400);
				@include transition(.2s);
				@include block;
				i {
					@include ml(10px); } }
			@include hover {
				@include bg($colorSec);
				a {
					@include co($white); } } } }
	.tab-content {
		min-height: 120px;
		padding: 0px;
		figure {
			@include w100;
			height: 240px;
			@include noScroll;
			@include radius(10px);

			img {
				@include w100; } }
		h2 {
			@include fs(1.5, 120%, 600);
			@include white;
			@include pointer;
			@include bg($color);
			border: thin solid $color;
			@include radius(0);
			@include noFlex;
			@include w100;
			padding: 10px 20px;
			margin: 0px;
			@include transition;
			@include bb(1px solid white);
			@include hover {
				@include bg($colorSec);
				border: thin solid $gray-dark; }
			i {
				@include right;
				@include rotate(90);
				@include transition;
				@include mt(2px); } }

		.text {
			@include none;
			border: thin solid $gray-light;
			padding: 10px;
			@include bgWhite;
			@include transition(); } } }

.accordeon-active {
	display: block $i; }

main.faq .tab-content h2.faq-active {
	@include bg($colorSec $i);
	border: thin solid $colorSec $i;
	i {
		@include rotate(-90); } }
