.modal-login {
	.container {
		@include w100;
		@include p0; }
	.row {
		@include mb(0); }
	.form-loguin {
		.myActions {
			a {
				@include ib;
				@include left;
				width: 50%;
				@include co($color);
				@include under;
				@include fs(1.4, 100%, 400);
				@include mt(8px);
				@include hover {
					@include co($color - 30); } } } } }

.form-help {
	@include bg($color);
	height: 150px;
	padding: 10px;
	@include relative;
	h2 {
		@include mb(10px);
		@include z(9);
		@include co($white);
		@include fs(2.2, 120%, 500); }
	.text {
		@include co($white);
		@include fs(1.6, 140%, 300);
		@include z(9); }
	.icon {
		@include bot-right(10px, 10px);
		@include opa(.2);
		@include z(1);
		i {
			@include fs(8);
			@include co($white); } } }

