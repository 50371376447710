//CSS3

@mixin transition($speed: 600ms) {
	transition: all $speed; }


@mixin rotate($rotate: 45) {
	transform: rotate($rotate + deg); }


@mixin translateY($translateY) {
	transform: translateY($translateY+px); }


@mixin translateX($translateX) {
	transform: translateX($translateX+px); }


@mixin radius($radius) {
	border-radius: $radius; }

@mixin radius-custom($radius-top, $radius-left, $radius-bottom, $radius-right) {
	border-radius: $radius-top $radius-left $radius-bottom $radius-right; }

@mixin opa($opa: .5) {
	opacity: $opa; }

@mixin rotateY($deg: 100) {
	transform: rotateY($deg+deg); }


@mixin rotateX($deg: 100) {
	transform: rotateX($deg+deg); }

@mixin scale($scaleX: 2, $scaleY: 2) {
	transform: scale($scaleX, $scaleY); }




// CUBIC
@mixin InOutBack($speed: 600ms) {
	transition: all $speed cubic-bezier(0.68, -0.55, 0.265, 1.55); }

// Animations

@mixin anim($value, $animation) {
	animation: $value 600ms $animation; }

@mixin grayFilter($grayFil: 100) {
	filter: grayscale($grayFil);
	-webkit-filter: grayscale($grayFil); }
