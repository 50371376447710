//$color: rgba(72, 107, 224, 1)



//=color($opa1: 1)
//	color: rgba(72, 107, 224, $opa1)
//
//=bgColor($opa2: 1)
//	background: rgba(72, 107, 224, $opa2)

//$colorSec: rgb(64, 54, 139)



//=colorSec
//	+co($colorSec)
//
//$colorTer: rgba(78, 153, 207,1)
//
//=colorTer
//	+co($colorTer)

//$Colors

$transparent: rgba(0,0,0,0);

//Cake
$cake: #E3DAD6;
$cake-dark: #BDB0AA;



//COLORS MIXINS

@mixin gray() {
	color: $gray; }

@mixin gray-dark() {
	color: $gray-dark; }

@mixin gray-light() {
	color: $gray-light; }

@mixin white() {
	color: $white; }


//=blue()
//	color: $blue

@mixin black {
	color: $black; }

@mixin bgWhite($opacity:1) {
	background: rgba(255,255,255,$opacity); }

@mixin bgBlack($opacity:1) {
	background: rgba(0,0,0,$opacity); }

$facebook: #3b5998;
$twitter: #4099FF;



//Gradient
@mixin degra {
	@include gradient(top,#fff, 10%, #f9f9f9, 80%); }

@mixin degraHeader {
	@include gradient(left,$color, 25%, $colorSec, 95%); }

@mixin degraTop {
	@include gradient(top,$color, 1%, $colorSec, 99%); }

@mixin degraRight {
	@include gradient(right,$color, 1%, $colorSec, 99%); }

@mixin degraLeft {
	@include gradient(left,$color, 1%, $colorSec, 99%); }


//Alerts

$error: #FF9494;
$success: #5fe35f;




