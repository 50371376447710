@mixin prefooter-active {
	@if $prefooter-active {
		@debug "$prefooter-active is #{$prefooter-active}";
		@include bb(4px solid $footer-colorBorder); }

	@else {
		@debug "$prefooter-active is #{$prefooter-active}"; } }

footer.fixed {
	@include bot-left; }

footer {
	@include tCenter;
	.actions {
		a {
			@include ib;
			@include va;
			margin: 0 20px; }
		img {
			@include ib;
			height: 40px;
			@include opa(.8);
			@include transition;
			margin: 20px 0 0;
			@include hover {
				@include opa(1); } } }

	@include pb(10px);
	.social {
		@include tCenter;
		margin: 20px 0;
		a {
			@include ib;
			@include tCenter;
			@include size(30px);
			@include radius(5px);
			margin: 0 0 0 2px;
			@include bg($white);
			@include transition;
			@include hover {
				@include bg($colorTer);

				i {
					@include white; } }
			i {
				@include mt(5px);
				@include co($colorTer);
				@include fs(2);
				@include transition(); } } }
	@include w100;

	@include z(99999999);
	@include co($footer-color);
	@include prefooter-active;
	@include patt($footer-bgPattern);
	@include bc($color );
	//	+gradient(top,$colorSec, 50%, $color, 99%)
	@include fs(1.4, 100%, 400);
	.text {
		@include co($gray-light + 20);
		@include fs(1.4, 150%, 300); }

	.capa {
		@include bg($color - 20);

		padding: 10px 0;
		.row {
			@include mb(0); } }
	a {
		@include co($color); }
	.row {
		@include mb(0); }
	figure {
		margin: 20px 0;
		@include w100;
		@include tCenter;
		img {
			height: 80px;
			@include opa(.6); } }
	nav {
		margin: 45px 0 0;
		@include bl(thin solid $white);
		ul {
			@include w100;
			@include tCenter;
			li {
				@include block;
				margin: 0 0px;
				a {
					padding: 10px 0px 10px 20px;
					@include tLeft;
					@include upper;
					@include block;
					@include transition;
					@include co($footer-anchorColor);
					@include hover {
						@include co($footer-anchorColor_hover); } } }

			.last {
				@include noBorder; } } }


	.contact-info {
		@include w100;
		margin: 20px 0 10px;
		@include fs(1.5, 100%, 400);
		@include tCenter;
		> div {
			@include ib;
			margin: 0 5px;
			i {
				@include mr(5px); }
			a {
				@include co($footer-anchorColor);
				@include under;
				@include hover {
					@include co($footer-anchorColor_hover); } } } } }



.postFooter .ahorranito,
.postFooter.copyright {
	@include fluid {
		@include mb(10px);
		@include tCenter;
		@include lh(120%); } }

.postFooter nav ul li a,
.postFooter .contact-info > div {
	@include mobil {
		@include lh(150%); } }

.ahorranito {
	span {
		@include co(#1d9ad4); }

	.ahorranito-icon {
		@include img(ahorranito);
		background-position: center;
		background-repeat: no-repeat;
		display: inline-block;
		height: 20px;
		vertical-align: middle;
		width: 20px; } }

.postFooter {
	padding: 10px 0 5px;
	@include bc($color - 10);
	.row {
		@include mb(0); }
	.copyright {
		@include mt(5px);
		@include block;
		@include co($white); }
	.ahorranito {
		@include tRight;
		@include mt(3px);
		a {
			@include ib;
			@include co($footer-anchorColor);
			@include va; }
		.ahorranito-icon {
			@include ib;
			@include va; } } }
