

//@AboutUs

$tab-color_active:  $color;
$tab-color: $colorSec;

$border-active: true !default;

@mixin border-active {
	@if $border-active {
		@debug "$border-active is #{$border-active}";
		border: 4px solid $gray-light; }

	@else {
		@debug "$border-active is #{$border-active}"; } }



main.aboutUs {
	figure {
		margin: 0 0 $space;
		@include size(100% 300px);
		@include border-active;
		@include figure; }

	.row {
		@include mb(0); }

	.tabs {
		height: 30px $i;
		@include bg($transparent $i);
		@include mb(20px);

		li {
			height: auto $i;
			@include bg($transparent $i);
			a {
				@include fs(1.6, 100%, 600);
				@include upper;
				@include co($tab-color $i); }
			.active {
				@include co($tab-color_active $i); } } }


	.tabs .indicator {
		@include bg($tab-color_active $i); }

	.tab-content {
		@include mb($space);
		h2 {
			@include fs(2, 100%, 700);
			@include mb($space); } } }

.workTeam {
    h2 {
        @include mt(20px); }
    .top-img {
        margin-bottom: 0px $i;
        img {
            margin-bottom: 0px; }
        .introduction {
            p {
                padding: 20px $i;
                font-size: 1.7rem $i;
                line-height: 150% $i;
                @include bc($white);
                font-weight: 400 $i;
                white-space: pre-wrap $i; } } } }

.workTeam {
	.top-img {
		img {
			border: 0px; } }

	h1 {
		@include mb(0px); }
	.panel {
		@include mb(20px);
		height: 100%;
		min-height: 600px;
		padding: 10px;
		.container {
			width: 100%; }
		.text {
			@include bb(thin dotted $gray);
			@include pb(10px); } }
	h3 {
		margin: 30px 0;
		padding: 10px 20px;
		@include fs(2, 100%, 400);
		@include upper;
		@include bg($color);
		@include co(white); }
	.text {
		@include mb(20px); }
	article {
		@include bb(thin solid $gray-light);
		margin: 5px 0 0;
		@include bb(thin solid $gray-light - 20);
		@include pb(0px);
		@include mb(30px);
		@include relative;
		h2 {
			@include fs(2, 100%, 400);
			@include co($color);
			margin: 0 0 $space;
			@include upper; }

		.position {
			padding: 5px 0;
			@include co($color);
			@include mb(10px);
			@include mr(20px);
			@include bt(thin dotted $gray-light - 20);
			@include bb(thin dotted $gray-light - 20);
			i {
				@include mr(5px); } }
		.mail {
			@include block;
			@include bt(thin dotted $gray-light - 20);
			padding: 5px 0 8px;
			@include mr(20px);
			i {
				@include mr(5px); } }

		figure {
			@include right;
			width: 100%;
			height: 200px;
			@include background;
 }			//border: 6px solid $gray-light - 20

		.icon {
			@include bot-left(-20px,50%);
			margin: 0 0 0 -30px;
			@include none;
			@include bg(white);
			@include tCenter;
			@include size(40px 30px);
			span {
				@include img(logotipo-gray);
				@include background(contain);
				@include opa(.6);
				@include block;
				@include grayFilter(1);
				@include size(40px 30px); } } }
	.logo {
		@include mt(20px);
		@include tCenter;
		@include block;
		img {
			height: 40px;
			@include ib;
			@include mb(10px); }
		a {
			@include block;
			@include fs(1.4, 120%, 300);
			@include co($color);
			@include hover {
				@include co($color - 20);
				@include under; } } } }



