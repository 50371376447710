//POSITIONS


@mixin fixed {
	position: fixed; }

@mixin absolute ($top:auto,$left:auto,$bottom:auto, $right:auto) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	position: absolute; }

@mixin relative {
	position: relative; }

@mixin top-left($top: 0, $left: 0) {
	@include absolute($top,$left,null,null); }

@mixin top-right($top: 0, $right: 0) {
	@include absolute($top,null,null,$right); }

@mixin bot-left($bot: 0, $left: 0) {
	//	+absolute(null,$bot,$left,null)
	position: absolute;
	bottom: $bot;
	left: $left; }

@mixin bot-right($bot: 0, $right: 0) {
	//	+absolute(null,null, $bot, $right)
	position: absolute;
	bottom: $bot;
	right: $right; }
