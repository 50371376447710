

//@Services
main {
    .introduction {
        padding: 20px;
        font-size: 1.7rem;
        line-height: 150%;
        @include bc($white);
        font-weight: 400; } }

main.services {
	.col-sm-12.col-md12 {
		@include none; }

	.panel {
		@include mb(0px);
		@include pt(0px);
		@include pb(0px); }


	article {
		@include tCenter;
		@include mb(20px);
		//		background-image: linear-gradient(#fff 30%, #ededed 90%)
		@include transition(.2s);
		padding: 0px;
		@include bc($gray-light + 10);
		height: 390px;
		border: thin solid $gray-light - 20;
		@include hover;
		//+bc($gray-light)
  //	box-shadow: (0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19))
		.card-content {
			padding: 0; }
		h2 {
			@include fs(2, 100%, 300);
			@include co($white);
			@include bg($color);
			padding: 10px;
			@include mb(10px); }
		.text {
			@include mb(10px);
			@include font-text;
			padding: 10px;
			@include fs(1.4, 150%, 300);

			@include co($gray-dark);
			@include tLeft; }
		p {
			text-align: left;
			padding: 0px 10px; }
		.card-reveal {
			i {
				@include fs(3);
				@include co($colorSec);
				@include relative;
				@include z(9999);
				@include mb(20px); }
			.text {
				@include fs(1.4, 140%, 300);
				@include co($gray-dark); } }
		.btn {
			@include ib;
			@include mt(20px);
			i {
				@include co(white); } } }

	figure {
		@include tCenter;
		height: 200px;
		@include background; }

	video {
		@include border;
		@include mb(20px); } }



main.services.categories {
	ul.list-group {
		@include bg($gray-light + 25);
		> li {
			@include bb(1px solid $colorSec);
			padding: 15px 10px;
			@include relative;
			@include before {
				@include content('');
				@include size(20px 4px);
				@include bg($color);
				@include transition;
				@include block;
				@include bot-left(-2px, 0%); }
			@include hover {
				@include bb(thin solid $gray); }

			> a {
				@include block;
				@include co($colorSec);
				@include fs(1.6, 100%, 500);
				@include hover {
					@include gray; } }

			> ul {
				@include none; } } } }

main.detail-services {
    .text.contentdescription {
        div {
            font-weight: 300 $i; }
        p {
            font-weight: 300 $i; } } }

