
main .text strong {
	@include co($color);
	@include fw(bold); }

.quotes strong {
	@include co($colorTer);
	@include fw(bold); }

//@detail

.detail {
	@include mb(0);
	.row {
		@include mb(0); }
	article {
		@include block;
		@include m0;
		@include borderFix; }
	figure {
		@include border;
		@include right;
		@include background;
		margin: 0 0 $space $space;
		@include size(500px 300px);
		@include noScroll;
		@include tCenter;
		img,
		.fancybox-thumb {
			@include size(500px 300px);
			@include block;
			@include relative; }

		.icon {
			@include bot-right(15px, 15px);
			@include transition(.2s);
			i {
				@include fs(2.4);
				@include white; } } }

	.price {
		@include none; }

	.text {
		@include mb(30px);
		@include font-text; }

	.video {
		@include centered(20px, 20px);
		@include tCenter; }

	.actions {
		@include tRight;
		@include lh(37px);
		margin: $space 0;
		@include noFont;
		a {
			@include white;
			@include pointer;
			margin: 0 5px;
			@include ib;
			i {
				@include f(3); } } } }



.myProjects {
	@include pb(0 $i);
	@include relative;
	.slider, .slider .slides {
		height: 75.4vh;
		img {
			@include s100; } }
	.slider .indicators {
		bottom: 20px; }

	.project-myText {
		@include top-left;
		width: 320px;
		@include bgBlack(.8);
		@include white;
		@include z(999);
		max-height: 100%;
		//	overflow-x: hidden
		//	overflow-y: auto
		padding: 20px;
		.actions {
			@include w100;
			@include block;
			@include mb(20px);
			@include relative; }
		h1 {
			padding: 10px 0;
			margin: 0px 0px 20px $i;
			@include fs(1.6, 140%, 600);
			@include bg(none);
			@include white;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(255, 255, 255, 0.3); }
		.text {
			@include white;

			@include w100;
			max-height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			height: 50vh; }

		.project-mySlider {
			@include w100;
			@include relative;
			@include z(9); } } }


.detail {
	@include fluid {
		.actions {
			@include tCenter; } }

	@include mobil {
		figure {
			max-width: 100%;
			margin: 0 0 20px;
			height: 160px;
			@include noFloat;
			img,
			.fancybox-thumb {
				max-width: 100%;
				height: 160px; } } } }

main.detail-services {
    .menu-service {
        @include bc($white + 100);
        padding: 10px; }
    .contentdescription {
        @include bc($white + 100);
        padding: 10px;
        height: 100%;
        position: relative;
        display: inline-block;
        figure {
            margin: auto;
            float: inherit; } }
    .news {
    	article {
    		border-bottom: 1px dashed #CCCCCC; } } }
