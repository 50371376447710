


//@Contact


main.contact {
	@include tLeft;
	textarea {
		height: 80px $i; }
	figure {
		@include tCenter;
		margin: 20px 0; }
	.modal-close {
		@include right; }
	.message-success {
		margin: 0 20px;
		@include fs(3, 100%, 100); }
	#map_wrapper,
	.mapping {
		height: 355px; }

	form {
		padding: 10px;
		@include bg($white + 100 $i);
		@include mt(20px); }

	label.error {
		@include co($error);
		@include tRight;
		left: $in;
		right: 0;
		@include fs(1, 100%, 300); }

	#map_wrapper {
		border: 4px solid $gray-light;
		@include noScroll;
		@include mt(20px); }
	.contact-data {
		@include mt($space);
		@include bc($white + 100 $i);
		@include co($color);
		@include mb(20px);
		padding: 10px $space 10px;
		p {
			@include mb(20px); }
		h3 {
			@include mb(20px);
			@include tCenter;
			@include pb(10px);
			@include fs(1.6, 100%, 400);
			@include upper;
			@include bb(thin solid $color); }
		ul {
			@include tCenter; }
		li {
			margin: 0 5px;
			@include mb(10px);
			@include ib;
			@include fs(1.4, 100%, 400);
			@include wauto;
			i {
				@include co($color); } } }
	input[type="submit"] {
		@include bg(none);
		@include pointer;
		@include noBorder;
		@include white;
		@include ls(.1rem); }

	.datos_contac {
		@include mt(20px); } }


