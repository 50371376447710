//SNIPPETS

@mixin mt($margin-top) {
	margin-top: $margin-top; }

@mixin ml($margin-left) {
	margin-left: $margin-left; }

@mixin mr($margin-right) {
	margin-right: $margin-right; }

@mixin mb($margin-bottom) {
	margin-bottom: $margin-bottom; }

//BORDES

@mixin bt($bt:thin solid $gray) {
	border-top: $bt; }

@mixin bb($bb:thin solid $gray) {
	border-bottom: $bb; }

@mixin bl($bl:thin solid $gray) {
	border-left: $bl; }

@mixin br($br:thin solid $gray) {
	border-right: $br; }


//PADDINGS

@mixin pt($pt) {
	padding-top: $pt; }

@mixin pb($pb) {
	padding-bottom: $pb; }

@mixin pl($pl) {
	padding-left: $pl; }

@mixin pr($pr) {
	padding-right: $pr; }

@mixin clear {
	clear: both; }

@mixin none {
	display: none; }

@mixin hidden {
	visibility: hidden; }

@mixin visible {
	visibility: visible; }

@mixin centered($center1: 0, $center2: 0) {
	margin: $center1 auto $center2; }

@mixin auto {
	margin: auto; }

@mixin lh($lineHeight: 100%) {
	line-height: $lineHeight; }

@mixin inline {
	display: inline; }

@mixin inline-block($vertical-align: top) {
	display: inline-block;
	vertical-align: $vertical-align;
	white-space: normal; }


@mixin fs($font-size: 1.4, $line-height: 100%, $font-weight: normal) {
	font-size: $font-size+rem;
	line-height: $line-height;
	font-weight: $font-weight; }

@mixin ma($margin1: 0, $margin2: 0, $margin3: 0, $margin4: 0) {
	margin: $margin1+rem $margin2+rem $margin3+rem $margin4+rem; }

@mixin pa($padding1,$padding2,$padding3,$padding4) {
	padding: $padding1+rem $padding2+rem $padding3+rem $padding4+rem; }


@mixin uppercase {
	text-transform: uppercase; }

@mixin upper {
	text-transform: uppercase; }

@mixin capi {
	text-transform: capitalize; }


@mixin left {
	float: left; }

@mixin right {
	float: right; }

@mixin under {
	text-decoration: underline; }

@mixin tCenter {
	text-align: center; }

@mixin tLeft {
	text-align: left; }

@mixin tRight {
	text-align: right; }

@mixin tJustify {
	text-align: justify; }

@mixin tJust {
	text-align: justify; }

@mixin ls($letter: 0px) {
	letter-spacing: $letter; }

@mixin cursive {
	font-style: italic; }

@mixin ti($indent: -999) {
	text-indent: $indent+rem; }

@mixin fw($weight) {
	font-weight: $weight; }

@mixin border {
	border: $borde; }


@mixin z($index) {
	z-index: $index; }

@mixin block {
	display: block; }

@mixin pointer {
	cursor: pointer; }

@mixin gradient($direction,$from, $value1,$to, $value2) {
	background-color: $from;
	background-image: linear-gradient($direction, $from $value1, $to $value2);
	background-image: -webkit-linear-gradient($direction, $from $value1, $to $value2);
	background-image: linear-gradient($direction, $from $value1, $to $value2); }
//    background-image: url('../img/bg_hexagono.png'), linear-gradient(left, $bg-brown_light 0%, $bg-brown_dark 100%)
@mixin gradientHeader {
	background: $bg-brown_light;
	background-image: url('../img/bg_hexagono.png');
	@include background-image(url('../img/bg_hexagono.png'), linear-gradient(left, $bg-brown_light 30%, $bg-gray 70%)); }

@mixin bg($background) {
	background: $background; }

@mixin co($color: $red) {
	color: $color; }

@mixin to($tox: 0, $toy: 0) {
	transform-origin: 0 0; }

@mixin cRight {
	clear: right; }

@mixin cLeft {
	clear: left; }

@mixin ib {
	@include inline-block; }

@mixin cover {
	background-size: cover; }

@mixin contain {
	background-size: contain; }

@mixin va($va: middle) {
	vertical-align: $va; }

@mixin f($f: 1.4rem) {
	font-size: $f; }

@mixin img($img2) {
	background-image: url(#{$img}#{$img2}.png); }

@mixin patt($patt2) {
	background-image: url(#{$patt}#{$patt2}.png); }



@mixin nr {
	background-repeat: no-repeat; }

@mixin bp($hori: center, $vert: center) {
	background-position: $hori $vert; }

@mixin bc($bc) {
	background-color: $bc; }

@mixin background($size: cover) {
	background-position: center center;
	background-size: $size;
	background-repeat: no-repeat; }
