.clear {
	clear: both; }
//
//.rounded
//	+radius(5px)
//
.left {
	@include left; }

.right {
	@include right; }
//
//
//.z999
//	+z(999)
//
//.hidden
//	+hidden

.b1 {
	@include b1; }

.b2 {
	@include b2; }

.b3 {
	@include b3; }

.none {
	@include none; }

.tRight {
	@include tRight; }

.tCenter {
	text-align: center; }

.tLeft {
	@include tLeft; }

.relative {
	@include relative; }

//.noFlex
//	+noFlex
//
//.w1024
//	width: 1024px
//	+centered
//	+relative
//
//.w100
//	width: 100%
//
//.noScroll
//	+noScroll

.hide {
	display: none !important; }

.relative {
	@include relative; }

.scrollY {
	overflow-y: auto !important;
	overflow-x: hidden !important; }

.grid {
	@include grid; }

.w100 {
	width: 100%; }
