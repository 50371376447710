//@highlight


@mixin highlight-efect {
	@if $highlight-efect {
		@debug "$highlight-efect is #{$highlight-efect}";
		box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }


	@else {
		@debug "$highlight-efect is #{$highlight-efect}"; } }

.areas {
	@include bc($gray-light + 10);
	padding: 20px 0;
	@include tLeft;
	@include m0;
	@include relative;
	.row {
		@include mb(0); }
	h2 {
		@include co($color);
		@include mb(15px);
		@include ib;
		@include w100;
		@include upper;
		@include tCenter;
		@include fs(3, 100%, 500);
		strong {
			@include co($gray-dark); }
		i {
			@include mr(10px);
			@include ib;
			@include va; } }

	article {
		@include relative;
		border: thin solid $gray-light - 5;
		height: 440px;
		@include bg(white);
		@include tCenter;
		@include transition(.2s);
		@include hover {
			@include bg($gray-light); }
		figure {
			height: 180px;
			@include background;
			@include radius(0px); }

		.icon {
			@include block;
			margin: 5px 0;
			@include tCenter;
			@include relative;

			i {
				@include fs(2.4);
				@include co($gray-light - 10); }
			@include before {
				@include content('');
				@include size(180px 1px);
				@include bg($gray-light - 10);
				@include top-left(10px, 20px); }
			@include after {
				@include content('');
				@include size(180px 1px);
				@include bg($gray-light - 10);
				@include top-right(10px, 20px); } }

		h3 {
			@include tLeft;
			padding: 0 10px 0 0;
			@include fs(2, 120%, 400);
			@include tCenter;
			@include co($gray-dark);
			@include block;
			@include relative;

			margin: 10px 0px 0; }


		p {
			@include transition;
			padding: 0 20px;
			@include tLeft;
			@include co($highlight-colorTxt);
			@include fs(1.6, 140%, 300); }


		.btn {
			@include bot-left(20px, 50%);
			@include ml(-59px);
			@include fs(1.4, 36px, 300);
			@include mr(0px);
			@include white;
			i {
				@include ml(10px);
				@include ib;
				@include va; } } } }

section.outstanding {
	@include tCenter;
	@include bg($gray-light);
	@include patt(0);
	@include relative;
	@include tLeft;
	margin: 0;
	padding: 25px 0 10px;

	h2 {
		@include co($white);
		@include ib;
		margin: 0 0 30px;
		@include wauto;
		@include tLeft;
		@include fs(3, 100%, 400);
		@include co($color);
		@include fs(2.6, 100%, 300);
		strong {
			@include co($gray-dark); } }

	a {
		@include block; }

	article {
		@include tCenter;
		@include block;
		@include noScroll;
		@include transition;
		@include relative;
		@include pointer;
		height: auto;


		//		&:hover
		//			box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21)

		.caption {
			@include bot-left(-140px, 0);
			padding: 10px;
			@include bgBlack(0);
			@include w100;
			@include transition(.2s);
			h3 {
				@include co($gray-dark); } }

		.caption-active {
			bottom: 0;
			@include bgBlack(.7);
			h3 {
				@include co($white); } }
		figure {
			@include size(100% 400px);
			margin: 0 auto;
			@include background; }


		h3 {
			@include tCenter;
			padding: 10px;
			@include transition(.4s);
			@include fs(1.8, 130%, bold);

			@include block;
			@include relative;
			@include fw(bold);

			margin: 0px 0 10px; }


		p {
			@include transition;
			@include tLeft;
			@include co($white);
			@include fs(1.6, 140%, 400); }

		li {
			@include co($white);
			@include before {
				@include co($white); } }
		.btn {
			@include ib;
			@include mt(10px);

			@include fs(1.4, 36px, 300);
			@include mr(20px);
			@include white;
			i {
				@include ml(10px); } }

		.text {
			@include tLeft;
			@include co($gray-dark); } } }




section.highlight {
	.row {
		@include mb(0px); }
	.news {
		@include tLeft;
		@include relative;
		h2 {
			@include co($color);
			@include mb(10px);
			@include ib;
			@include w100;
			@include upper;
			@include tLeft;
			@include fs(2.6, 100%, 400);
			i {
				@include mr(10px);
				@include ib;
				@include va; } }
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto {
			bottom: 20px;
			@include tCenter;
			@include p0; }

		.bx-wrapper .bx-pager.bx-default-pager a {
			border: $border-bullet;
			width: $size-bullet - 4px;
			height: $size-bullet - 4px;
			margin: 0 $space-bullet;
			@include bg($color-bullet + 40 $i);
			@include radius(100%);
			@include transition(.2s);
			@include f(0rem); }

		.bx-wrapper .bx-pager.bx-default-pager a:hover,
		.bx-wrapper .bx-pager.bx-default-pager a.active {
			@include bg($color-bullet_active);
			border: $border-bullet_active; }


		article {
			@include relative;
			//height: 240px
			height: auto;
			@include pb(60px);
			@include bb(thin dotted $gray);
			figure {
				height: 200px;
				@include background;
				@include radius(4px);
				border: 4px solid $gray-light; }

			.icon {
				@include block;
				margin: 10px 0;
				@include tCenter;
				@include relative;

				i {
					@include fs(2.4);
					@include co($gray ); }
				@include before {
					@include content('');
					@include size(200px 1px);
					@include bg($gray);
					@include top-left(10px, 30px); }
				@include after {
					@include content('');
					@include size(200px 1px);
					@include bg($gray);
					@include top-right(10px, 30px); } }

			h3 {
				@include tLeft;
				padding: 0 10px 0 0;
				@include fs(2.2, 130%, 400);
				@include co($gray-dark);
				@include block;
				@include relative;

				margin: 15px 20px 0; }


			p {
				@include transition;
				padding: 0 20px;
				@include tLeft;
				@include co($highlight-colorTxt);
				@include fs(1.6, 160%, 300); }


			.btn {
				@include ib;
				@include mt(10px);

				@include fs(1.4, 36px, 300);
				@include mr(20px);
				@include white;
				i {
					@include ml(10px); } } } } }
body {
	section.carousel {
		.bx-wrapper {
			.bx-pager.bx-default-pager {
				a {
					background-color: $gray + 10 $i; } } } } }


section.highlight {

	@include tCenter;
	@include bg($highlight-bgColor);
	@include patt($highlight-bgPattern);
	@include relative;
	@include tCenter;
	margin: 0;
	padding: 25px 0 10px;



	.weDo {
		@include tLeft;

		h2 {
			@include co($color);
			@include mb(20px);
			@include ib;
			@include w100;
			@include bb(thin solid $color);
			@include tLeft;

			padding: 0 10px 5px 10px;
			@include fs(2.6, 100%, 300); }

		.text {
			@include fs(1.6, 150%, 300);
			padding: 0 10px; }


		.icon {
			@include block;
			margin: 20px 0 0;
			@include tCenter;
			@include relative;
			i {
				@include fs(2.4);
				@include co($gray-light - 30 ); }
			@include before {
				@include content('');
				@include size(230px 1px);
				@include bg($gray-light - 30);
				@include top-left(10px, 10px); }
			@include after {
				@include content('');
				@include size(230px 1px);
				@include bg($gray-light - 30);
				@include top-right(10px, 10px); } }


		h3 {
			@include tCenter;
			padding: 0 10px 0 0;
			@include fs(2, 130%, 700);
			@include co($highlight-colorSubtitle);
			@include block;
			@include relative;

			margin: 15px 20px 0; }


		p {
			@include transition;
			padding: 0 20px;
			@include tLeft;
			@include co($highlight-colorTxt);
			@include fs(1.6, 160%, 300); } }



	.hireUs {
		@include bg($color);
		height: 300px;
		padding: 20px;
		@include relative;
		@include noScroll;
		@include bb(4px solid $colorSec);
		h2 {
			@include co($gray-light);
			@include fs(2.6, 100%, 300);
			@include mb(15px);

			@include relative; }
		.text {
			@include co($white);
			@include fs(1.7, 150%, 100);
			text-align: justify; }
		.btn {
			@include mt(10px);
			//			+before
			//				+content('')
			//				+size(100px 1px)
			//				+bgWhite
			//				+top-left(14px, 0)
			//			+after
			//				+content('')
			//				+size(100px 1px)
			//				+bgWhite
 }			//				+top-right(14px, 0)
		.icon {
			@include opa(.1);
			@include size(182px 120px);
			@include top-left(50%, 50%);
			margin: -60px 0 0 -91px;
			@include background;
			i {
				@include fs(20); } } } }

section.highlight {
	.book {
		@include noScroll;
		@include radius(0px);
		height: 280px;
		padding: 20px;
		@include bg($colorSec + 60);
		@include relative;
		h2 {
			@include co($color);
			@include mb(10px);
			@include ib;
			@include w100;
			@include upper;
			@include tLeft;
			@include fs(2.6, 100%, 400); }
		figure {
			width: 40%;
			height: 100px;
			@include left;
			@include mr(10px);
			border: 4px solid $gray-light - 10;

			@include background; }
		.icon2 {
			@include bot-right(-60px, -50px);
			@include size(240px 320px);
			@include background;
			@include opa(.2); }
		h3 {
			@include co($color);
			@include fs(1.8, 120%, 400);
			@include mb(15px);
			@include tLeft;
			@include co($gray-dark); }
		.icon {
			@include block;
			@include relative;
			@include mb(10px);
			@include tCenter;
			@include before {
				@include content('');
				@include block;
				@include top-left(13px, 10px);
				@include bg($white);
				@include size(130px 1px); }
			@include after {
				@include content('');
				@include block;
				@include top-right(13px, 10px);
				@include bg($white);
				@include size(130px 1px); }
			i {
				@include white;
				@include fs(1.8);
				@include pt(6px);
				@include size(30px);
				@include radius(100%);
				@include bg($colorTer); } }
		p {
			padding: 10px 0px;
			@include co($gray-dark);
			@include tLeft;
			@include fs(1.6, 150%, 300); }

		.bx-controls.bx-has-pager.bx-has-controls-direction {
			@include none; }
		.btn {
			@include mt(15px $i); } } }
