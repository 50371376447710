
//@Header

@mixin menu-active {
	@if $menu-active {
		@debug "$menu-active is #{$menu-active}";
		@include co($color-menu_hover);
		@include noUnder;
		@include bg($colorSec); }

	@else {
		@debug "$menu-active is #{$menu-active}"; } }


.dropdown-content li > a {
	@include fw(300 $i);
	@include f(1.5rem $i);

	@include co($gray $i);
	@include hover {
		@include co($color $i);
		@include bg($gray-light); }
	i {
		@include mr(10px); } }

@mixin mainMenu {
	@include mt(30px);
	@include tCenter;
	//	+bt(thin solid $gray-light - 10)
	//	+bb(thin solid $gray-light - 10)

	ul {
		@include tCenter;
		@include noFont; }
	li {
		@include tCenter;
		margin: 0 0 0;
		padding: 2px 22px;
		@include relative;
		//		+before
		//			+content('')
		//			+top-right
		//			+size(2px 10px)
		//			+bg(white)
		//			+block

		@include ib;
		//		+br(thin solid $gray-light - 20)

		i {
			@include ml(5px);
			@include va;
			@include lh(90% $i); } }

	a {
		@include va;
		@include fs(1.4, 100%, 400);
		@include co($gray-dark + 30);
		@include upper;
		@include ib;
		padding: 5px 12px;
		@include br(thin solid $gray-light - 20);
		@include transition(.2s);
		@include noUnder;
		@include relative;
		@include hover {
			@include co($color); }
		i {
			@include f(1.2rem $i); }
		@include nth6 {
			@include noBorder; }

		a.high {
			border: 2px solid $colorSec;
			@include radius(4px);
			@include ml(12px);
			@include mt(-2px); }


		.subnav,
		.dropdown {
			@include bg($white);
			@include relative;

			li {
				@include block;
				@include bb(thin solid $gray-light);
				@include relative;
				a {
					@include tCenter;
					@include relative;
					@include fs(1.5, 100%, 400); }





				//		.myDropdown-active
				//			+b1

				//				+before
				//					+size(100% $height-under)
				//					+bg($color-menu_hover)
				//			+before
				//				+content('')
				//				+size(10px $height-under)
				//				+bg($color-menu)
				//				+block
				//				+bot-left(0%, 0%)
 } }				//				+transition

		@include nth5 {
			@include noBorder; } }

	.icon {
		@include bg($color);
		@include tCenter;
		width: 40px;
		padding: 0 $i;
		height: 35px;
		a {
			@include tCenter;
			@include block;
			height: 35px;
			padding: 0 $i;
			@include hover {
				@include bg($colorSec); }
			i {
				@include m0;
				@include fs(2);
				@include mt(7px);
				@include transition(.2s); } } }



	.current {
		a {
			@include co($color-menu_hover);
			@include noUnder;
			@include before {
				@include size(100% $height-under);
				@include bg($color-menu_hover); } } } }

.dropdown-content li>a {
	@include co($color-menu $i);
	@include hover {
		@include co($gray-dark $i); } }

.dropdown-content {
	width: auto $i;
	overflow: hidden $i;
	@include radius(0px);
	//	top: 98px $i
	.divider {
		@include bg($gray-light - 20); } }


.dropdown-button {
	i {
		@include ib;
		@include va; } }

nav {
	@include mainMenu;
	.row {
		@include mb(0); } }


.button-collapse {
	@include top-left(10px, 10px);
	@include z(996);
	@include transition;
	@include pointer;
	i {
		@include fs(3); } }

#sidenav-overlay,
.drag-target {
	@include pointer; }

.container > .logo {
	@include fluid {
		width: 100%;
		max-width: 100%;
		@include tCenter; } }


.myMenu {
	.logo {
		@include none; } }

.side-nav {
	li {
		@include bb(thin solid $gray-light);
		a {
			@include co($color);
			@include block;
			padding: 10px;
			@include fs(1.6, 100%, 400); } } }



.fixed-menu {
	@include top-left;
	@include w100;
	@include fixed;
	@include z(99); }

.fixed-nav {
	@include top-left(80px, 0);
	@include w100;
	@include fixed;
	@include z(99); }



.button-collapse {
	i {
		@include co($menuMobile-color); } }

header nav {
	strong {
		@include co($color);
		@include fw(bolder); } }


.submenu-active {
	opacity: 1 $i;
	@include transition(.4s);
	pointer-events: visible $i; }


.submenu {
	@include transition(.4s);
	@include p0;
	pointer-events: none;
	@include top-left(40px, 0);
	background-color: #FFFFFF;
	margin: 0px;
	@include opa(0);
	min-width: 100px;
	z-index: 1000;
	width: 100%;
	height: auto;
	overflow-y: hidden;
	will-change: width, height, opacity;
	li {
		@include block; } }

#dropdown1 {
	width: 168px $i;
	@include bb(3px solid $colorSec);
	top: 98px $i; }

#dropdown2 {
	width: 229px $i;
	@include bb(3px solid $color);
	top: 98px $i; }

.dropdown-content li>a,
.dropdown-content li>span {
	padding: 10px $i; }

#dropdown3 {
	width: auto $i;
	min-width: 240px;
	@include bb(1px solid $color);
	top: 151px $i;
	@include ml(-4px);
	@include z(99999999999 $i); }

nav li {
	@include relative;
	a {
		@include relative; } }


