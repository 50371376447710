//section.video
//	+size(100% 300px)
//	+pointer
//	video
//		height: auto $i
//		width: 100% $i


section.video {
	@include relative;
	@include tCenter;
	margin: 0;
	@include pointer;

	h1 {
		@include co($white);
		@include fs(4, 100%, 300);
		@include ib;
		@include wauto;
		@include mb(20px);
		strong {
			@include block;
			@include fs(5, 100%, 400); } }

	.text {
		@include ib;
		@include co($white);
		margin: 0px;
		@include fs(2.4, 150%, 300);
		@include w100;
		a {
			@include co($white);
			@include ib;
			margin: 0 10px 20px;
			@include under; }
		p {
			@include fs(2.2, 150%, 100);
			@include co($white); } }

	i {
		@include mt(20px);
		@include fs(8);
		@include co($white);
		@include opa(.4);
		@include borderFix; }

	.capa {
		@include bgBlack(.2);
		padding: 40px 0;
		@include transition(.2s); } }

//	+hover
//		.capa
//			+bgBlack(.5)

section.banner {
	@include w100;
	height: $slider-height;

	.bx-wrapper .bx-controls-auto,
	.bx-wrapper .bx-pager {
		width: 50%; } }

.slider {
	height: $slider-height;
	@include top-left;
	@include z(2);
	@include w100;
	.slides {
		height: $slider-height; }
	h2 {
		@include co($white);
		@include fs(3, 120%, 300);
		@include upper;
		@include mb(30px);
		@include w100;
		@include mt(20px); }

	p {
		@include co($white);
		@include fs(2, 150%, 100);
		@include w100;
		@include mb(30px); }

	.btn {
		@include upper;
		margin: 0 5px 10px; }
	.btn-link {
		@include block;
		@include upper;
		@include co(white);
		@include fs(1.6, 150%, 300);
		@include hover {
			@include co($colorSec); } }

	.caption {
		@include tCenter;
		top: 0px;
		height: 100%;
		.caption-container {
			@include top-right();
			@include size(340px auto);
			@include bgBlack(.8);
			padding: 20px; }


		.caption-container.left {
			@include tLeft;
			@include left; }

		.caption-container.right {
			@include tRight;
			@include right; } }

	.indicators {
		bottom: 10px;
		text-align: $bullet-align;
		@include z(99);
		li.indicator-item {
			border: $border-bullet;
			width: $size-bullet;
			height: $size-bullet;
			margin: 0 $space-bullet;
			@include bg($color-bullet);
			@include radius(100%);
			@include transition;
			&.active {
				@include bg($color-bullet_active);
				border: $border-bullet_active; } } } }



.slider {
	@include mobil {
		h2 {
			@include fs(2.4, 120%, 700); }
		p {
			@include fs(1.6, 140%, 400); } }

	@include fluid {
		img {
			@include background; } } }


.mySlide {
	height: $slider-height;
	@include background;
	@include bc($gray-light);
	.caption {
		@include top-right(90px, 0);
		@include size(400px 300px);
		@include block;
		@include bgBlack(.7);
		@include tCenter;
		padding: 20px;
		.next,
		.prev {
			@include none;
			@include bot-left(10px, 50%);
			@include size(40px);
			@include radius(0);
			border: 2px solid $white;
			@include tCenter;
			@include ml(-20px);
			@include z(9999999999);
			@include transition(.2s);
			i {
				@include fs(2);
				@include mt(5px);
				@include co($white);
				@include transition(.2s); }
			a {
				@include block;
				@include size(40px);
				@include top-left; }
			@include hover {
				border: 2px solid $colorSec;
				i {
					@include co($colorSec); } } }


		.prev {
			@include top-left(10px, 50%); }

		h2 {
			@include co($white);
			@include fs(3, 120%, 300);
			@include mt(0px);
			@include upper; }
		p {
			@include co($white);
			@include fs(1.8, 140%, 100);
			@include w100;
			margin: 10px 0 20px; }
		.text {
			@include tLeft;
			@include co($white);
			@include fs(1.6, 100%, 300);
			ul li::before {
				top: 3px; } }
		.actions {
			@include noFont;
			margin: 40px 0;
			a {
				@include f(1.4rem);
				margin: 0 5px; } } } }

.banner {
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		bottom: 10px; }

	.bx-wrapper .bx-pager.bx-default-pager {
		@include tRight; }

	.bx-wrapper .bx-pager.bx-default-pager a {
		border: $border-bullet;
		width: $size-bullet;
		height: $size-bullet;
		margin: 0 $space-bullet;
		@include bg($color-bullet);
		@include radius(100%);
		@include transition(.2s);
		@include noFont; }

	.bx-wrapper .bx-pager.bx-default-pager a:hover,
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		@include bg($color-bullet_active);
		border: $border-bullet_active; }

	.btnTer {
		@include hover {
			border: 2px solid $white $i; }
		@include after {
			@include none; } } }



