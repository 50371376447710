//@carrousel

section.carousel {

	@include noScroll;
	padding: 20px 0 10px;
	@include m0;
	@include w100;
	@include relative;
	@include tLeft;
	.bgcarousel {
		@include bc($gray-light);
		width: 100%;
		height: 100%;
		display: inline-block;
		position: relative;
		padding: 20px 0px 10px;
		.bx-pager {
			//+b1
			.bx-pager-item {
                //+b2
                a {
                    //+b3
                    background-color: red $i; } } } }
	figure {
		@include tCenter; }
	img {
		@include grayFilter(0);
		@include hover {
			@include grayFilter(0); } }

	i {
		@include fs(12);
		@include co($white);
		@include opa(.4); }

	h2 {
		@include co($color);
		@include mb(20px);
		@include ib;
		@include w100;
		@include upper;
		@include tCenter;
		@include fs(3, 100%, 500);
		strong {
			@include co($colorSec); } }

	h3 {
		margin: 10px 0 0;
		@include fs(1.6, 100%, 300);
		@include tCenter;
		@include co($color); }

	.text {
		@include fs(1.5, 140%, 300);
		@include co($white);
		@include bb(thin solid $white);
		@include tLeft;
		@include pb(10px); }

	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		bottom: -40px $i;
		@include none; }

	.bx-wrapper .bx-pager.bx-default-pager a {
		@include bg($carousel-bgBullet $i);
		@include size($carousel-sizeBullet);
		@include radius(100%);
		margin: 0 $carousel-spaceBullet $i;
		border: $carousel-borderBullet;
		@include transition; }
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		@include bg($carousel-bgBullet_active $i);
		border: $carousel-borderBullet_active; }


	.container-responsive {
		width: 100%;
		@include centered; } }

//section.carousel
//	+mobil
//		.container-responsive
//			width: 70%
//			+b2
