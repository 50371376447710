


//@Services


main.libraries {
	article {
		@include hover {
			figure {
				i {
					@include co($color); } } }
		h2, p {
			@include co($gray $i); }
		p {
			@include mt(20px); }
		.btn {
			@include co(white);

			@include f(1.2rem); }

		h2 {
			@include fs(1.6, 100%, 700);

			span {
				@include ib;
				width: 88%;
				@include va; }

			i {
				@include ib;
				@include va; } } }



	figure {
		@include tCenter;
		@include figure;
		i {
			@include transition; } }


	.card-action {
		@include tCenter;
		padding: 10px $i; }


	.card-title {
		@include lh(100%); }

	i {
		@include pointer; }

	.btn {
		i {
			@include ml(10px);
			@include co(white); } }

	.card-content {
		padding: 10px $i; } }

main.libraries.categories {
	ul.list-group {
		@include bg($gray-light + 25);
		> li {
			@include bb(1px solid $colorSec);
			padding: 15px 10px;
			@include relative;
			@include before {
				@include content('');
				@include size(20px 4px);

				@include transition;
				@include block;
				@include bot-left(-2px, 0%); }
			@include hover {
				@include bb(thin solid $gray); }

			> a {
				@include block;

				@include fs(1.6, 100%, 500);
				@include hover {
					@include gray; }
				i {
					@include mr(10px); } }
			> ul {
				@include none; } } } }

