
//GRID

@mixin box {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch; }

@mixin column {
  display: flex;
  flex-flow: column nowrap; }

@mixin myAlign($align-item: flex-start) {
  align-items: $align-item; }

@mixin flex($flex: 1) {
  flex: $flex; }

@mixin item($align-self: auto) {
  align-self: $align-self; }

@mixin space($value:space-between) {
  justify-content: $value; }

@mixin noWrap {
  flex-flow: row nowrap; }

@mixin cont($cont: stretch) {
  align-content: $cont; }

//GRID

$end: flex-end;
$start: flex-start;
$cen: center;
$bet: space-between;
$aro: space-around;
$base: baseline;
$str: stretch;

@mixin grid {
  @include box;
  @include myAlign;
  @include space;
  @include cont; }
