//SITE SETTINGS


//@Colors
$color: #004382;
$colorSec: #b7b7b7;
$colorTer: #00626B;
$colorQua: #00B4B0;
$gray: #808285;
$gray-light: $gray + 150;
$gray-dark: $gray - 80;
$white: #f6f6f6;
$black: #111111;
@mixin bgColor($opa) {
	background-color: rgba(0, 40, 85, $opa); }

$yellow: rgba(241, 139, 33, 1);
$green: rgba(169, 174, 55, 1);
$blue: rgba(0, 96, 127, 1);
@mixin bgYellow($opa: .8) {
	background-color: rgba(241, 139, 33, $opa); }
@mixin bgGreen($opa: .8) {
	background-color: rgba(169, 174, 55, $opa); }
@mixin bgBlue($opa: .8) {
	background-color: rgba(0, 96, 127, $opa); }

$gray: #A6A8AB;
$gray-light: $gray + 80;
$gray-dark: $gray - 90;
$white: #f6f6f6;
$black: #111111;

//@Colors-elements
$color-title: $color;
$color-subtitle: $color;
$color-txt: $gray-dark;
$color-anchor: $color;

//@spaces
$space: 20px;
$space-mini: $space / 2;
$space-big: $space * 2;

//@Anchors
$anchor-color: $color;
$anchor-colorActive: $colorSec;
$anchor-sizeFont: 1.2;

//@Transitions
$anchor-transition: .2s;

//@Borders
$borde: thin solid $gray-light;


//@Header
$logo-height: 60px;
$preheader-active: false !default;
$header-colorBorder: $color;
$header-shadow: false !default;

//@Menu
$margin-menu: 60px;
$height-under: 3px;
$space-menu: 12px;
$color-menu: $gray-dark;
$color-menu_hover: $color;
$menu-active: false !default;
$menuMobile-color: $colorSec;

//@Langs
$lang-bgColor: $color;
$lang-colorTxt: $white;
$lang-colorSeparator: $white;
$lang-colorHover: $gray-light;
$lang-colorActive: $gray-light;


//@Banner
$slider-height: 70vh;
$bullet-align: center;
$size-bullet: 26px;
$space-bullet: 3px;
$color-bullet: transparent;
$border-bullet: 6px solid $color;
$color-bullet_active: transparent;
$border-bullet_active: 6px solid $gray;

//@INFO
$info-bgPattern: 0;
$info-bgColor: $gray-light;
$info-colorTitle: $color;
$info-colorTxt: $gray-dark;
$info-colorBorder: $color;

//@Highlight
$highlight-bgPattern: 0;
$highlight-bgColor: white;
$highlight-colorTitle: $white;
$highlight-colorBordertitle: $highlight-colorTitle;
$highlight-colorSubtitle: $gray-dark;
$highlight-colorTxt: $gray-dark;
$highlight-colorBorder: $colorSec;
$highlight-efect: false !default;
$highlight-height: 400px;
$highlight-heightFigure: 140px;

//@Carousel
$carousel-bgPattern: 5;
$carousel-bgColor: $gray-light - 10;
$carousel-colorTitle: $white;
$carousel-colorBordertitle: $carousel-colorTitle;
$carousel-colorSubtitle: $white;
$carousel-sizeBullet: 20px;
$carousel-spaceBullet: 2px;
$carousel-bgBullet: $colorSec;
$carousel-borderBullet: 2px solid $colorSec;
$carousel-borderBullet_active: 2px solid $white;
$carousel-bgBullet_active: $white;


//@Footer
$prefooter-active: false !default;
$footer-colorBorder: $color;
$footer-color: $white;
$footer-bg: $color + 10;
$footer-bgPattern: 0;
$footer-anchorColor: white;
$footer-anchorColor_hover: $white;


